import Head from "next/head";

const SEO = props => {
	const { seo } = props;
	const {
		MetaTitleContainer,
		MetaTagContainer,
		MetaLinkContainer
	} = seo;

	return (
		<Head>
			<title key="title">{MetaTitleContainer.title.title}</title>
			<meta name="description" content={MetaTagContainer.description ? MetaTagContainer.description.content : ''} />
			<meta property="og:site_name" content={MetaTagContainer['og:site_name'] ? MetaTagContainer['og:site_name'].content : ''} />
			<meta property="og:description" content={MetaTagContainer['og:description'] ? MetaTagContainer['og:description'].content : ''} />
			<meta property="og:title" content={MetaTagContainer['og:title'] ? MetaTagContainer['og:title'].content : ''} />
			<meta property="og:type" content={MetaTagContainer['og:type'] ? MetaTagContainer['og:type'].content : ''} />
			<meta property="og:url" content={MetaTagContainer['og:url'] ? MetaTagContainer['og:url'].content : ''} />
			<meta property="og:image" content={MetaTagContainer['og:image'] ? MetaTagContainer['og:image'].content : ''} />
			<meta property="twitter:site" content={MetaTagContainer['twitter:site'] ? MetaTagContainer['twitter:site'].content : ''} />
			<meta property="twitter:creator" content={MetaTagContainer['twitter:creator'] ? MetaTagContainer['twitter:creator'].content : ''} />
			<meta property="twitter:title" content={MetaTagContainer['twitter:title'] ? MetaTagContainer['twitter:title'].content : ''} />
			<meta property="twitter:description" content={MetaTagContainer['twitter:description'] ? MetaTagContainer['twitter:description'].content : ''} />
			<meta property="twitter:url" content={MetaTagContainer['twitter:url'] ? MetaTagContainer['twitter:url'].content : ''} />
			<meta property="twitter:card" content={MetaTagContainer['twitter:card'] ? MetaTagContainer['twitter:card'].content : ''} />
			<meta property="twitter:image" content={MetaTagContainer['twitter:image'] ? MetaTagContainer['twitter:image'].content : ''} />
			<meta itemProp="name" content={MetaTagContainer['og:site_name'] ? MetaTagContainer['og:site_name'].content : ''} />
			<meta name="robots" content={MetaTagContainer['robots'] ? MetaTagContainer['robots'].content : 'all'} />
			<meta name="keywords" content={MetaTagContainer['keywords'] ? MetaTagContainer['keywords'].content : ''} />
			<link rel="canonical" href={MetaLinkContainer['canonical'] ? MetaLinkContainer['canonical'].href : ''} />
		</Head>
	);
};

export default SEO;